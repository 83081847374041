import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Foundation Setup`}</h2>
    <p>{`Legion design Libraries are separated into multiple projects. The first step, make sure you were invited to project file from the `}<a parentName="p" {...{
        "href": "https://forms.gle/vNvoJ5t6FDM7D1VD8"
      }}><strong parentName="a">{`Request Form`}</strong></a>{`, then check the available library for use in the table of list library or the project you assigned, and select the design library before you start designing.`}</p>
    <h2>{`Define colors from brand`}</h2>
    <p>{`This section will guide you to begin by creating your brand or product logo as the first step in establishing your identity, followed by defining primary, secondary, tertiary, and accent colors. This ensures a cohesive visual identity across all brand touchpoint.`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/U3GKbPnn_0Y?si=7MxRRuZOvj909aPH",
        "title": "YouTube video player",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        "referrerPolicy": "strict-origin-when-cross-origin",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <h3>{`1. Create your brand or product logo`}</h3>
    <p>{`Bein by importing your logo into Figma. This logo will represent your brand’s identity, so it’s important to have it ready for customization. By importing it into Figma, you can make adjustments that help align it with your overall vision for the brand.`}</p>
    <h3>{`2. Create a Color Palette for Your Brand`}</h3>
    <p>{`Use a plugin like `}<a parentName="p" {...{
        "href": "https://www.figma.com/community/plugin/929607085343688745/color-shades"
      }}>{`Color Shades`}</a>{` to create a color palette for your brand. A color palette is a collection of colors that will be used across your designs. You can customize the colors that the plugin generates by changing the labels or adjusting the shades. This step helps you define the main colors that represent your brand’s look and feel.`}</p>
    <h3>{`3. Define primary and secondary olors`}</h3>
    <p>{`Understand the difference between primary and secondary colors within your color palette. Primary colors serve as the fundamental building blocks, while secondary colors add depth and dimension to your palette.`}</p>
    <h3>{`4. Define tertiary and accent colors`}</h3>
    <p>{`Dive into tertiary and accent colors to enrich your palette. Tertiary colors introduce subtle variations, enhancing the depth and complexity of your design. Accent colors, on the other hand, highlight key elements, drawing attention to essential aspects of your product design.`}</p>
    <h3>{`5. Add the colors to local variables`}</h3>
    <p>{`Once you have your color palette ready, the next step is to save these colors as local variables in Figma. Local variables help you keep track of your colors and make sure you use them consistently across your design. By setting them as variables, you also make it easier to update or change the colors in the future without needing to edit each desig nelement individually.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Define Your brand’s colors`}</strong>{`: Start by uploading your logo into Figma and generate a custom color palette using a plugin like `}<a parentName="p" {...{
          "href": "https://www.figma.com/community/plugin/929607085343688745/color-shades"
        }}>{`Color Shades`}</a>{`. After making adjustments, add these colors to your local variables for easy documentation and future updates. Finally, apply the colors to your logo, allowing for seamless customization across your designs using the Legion V3 design system.`}</p>
    </InlineNotification>
    <h2>{`Define right typography`}</h2>
    <p>{`This section meticulously defines the appropriate font families and types to ensure a cohesive visual identity across all brand touchpoint. By selecting the right typography, including font families and types, we empower your brand to communicate effectively and leave a lasting impression on your audience.`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/UKAP2_a4MuM?si=OHlOn3oJOulKLeQ2",
        "title": "YouTube video player",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        "referrerPolicy": "strict-origin-when-cross-origin",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <h3>{`1. Select and Adjust Font Family`}</h3>
    <p>{`Start by using the components provided by Legion V3. You can easily customize these components to meet your needs by using available assets and adjusting the local variables.`}</p>
    <h3>{`2. Customize Font Style`}</h3>
    <p>{`Using Legion V3, you can easily adjust key typography elements like font size, weight, and line height. These changes are instantly applied to the components, ensuring everything updates automatically and stays visually consistent.`}</p>
    <h3>{`3. Maintain and Update Typography`}</h3>
    <p>{`Legion V3 provides flexibility to continuously refine your typography by adjusting variables. If custom text styles are already provided by the base variables in Legion, which follow UI principles, customization becomes even easier. This helps you keep your design consistent across platforms and easily adapt to new branding or design needs.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Typography Workflow`}</strong>{`: Easily customize your font family, size, weight, and line height using Legion V3’s local variables. These changes are automatically applied across all components, ensuring a consistent and cohesive design. Refine your typography as needed to adapt to evolving visual or branding requirements.`}</p>
    </InlineNotification>
    <h2>{`Define Icon System and Illustration`}</h2>
    <p>{`In this section we establish a unified icon system and illustration style that seamlessly aligns with your brand identity. By defining icons and illustrations that reflect your brand’s personality and values, we ensure consistency and impactful visual communication across all channels.`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/oYt6TuTIbgE?si=nWYmoLIundbRrnzG",
        "title": "YouTube video player",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        "referrerPolicy": "strict-origin-when-cross-origin",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <h3>{`1. Select Predefined Components`}</h3>
    <p>{`Start by using the components provided by Legion V3. You can easily customize these components to meet your needs by using available assets and adjusting the local variables.`}</p>
    <h3>{`2. Customize the Icon System`}</h3>
    <p>{`Access the local variables in Legion V3 t ochange key elements like button icons. For example, modify the primary color through settings like `}<inlineCode parentName="p">{`solidDefaultLabel`}</inlineCode>{` to match your design requirements.`}</p>
    <h3>{`3. Adjust Icons Easily`}</h3>
    <p>{`You can quickly update and adjust the icons using Legion’s built-in library. Legion V3 allows you to change icons automatically, ensuring consistency across all your components.`}</p>
    <h3>{`4. Maintain Illustration Consistency`}</h3>
    <p>{`Keep your icons and illustrations consistent by following Legion’s system. Set guidelines for styles, colors, and sizes to ensure your brand visuals stay unified across all platforms.`}</p>
    <InlineNotification mdxType="InlineNotification">
      <p><strong parentName="p">{`Legion V3 provides an efficient way to manage icon systems and illustrations`}</strong>{` by utilizing local variables for customization. Consistency across all components is ensured by applying a unified system for adjusting icon properties like color, size, and style. Clear guidelines for illustrations, including standardized elements such as style, colors, and proportions, help maintain a cohesive visual identity throughout your platform.`}</p>
    </InlineNotification>
    <h2>{`Finish setup foundation`}</h2>
    <p>{`This section finalizes the establishment of foundational elements, by ensuring all components are meticulously configured, your brand’s design system achieves cohesion and consistency across all aspects.`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/QrGj0LzSPtE?si=fqSOI4HptMGpWhHP",
        "title": "YouTube video player",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
        "referrerPolicy": "strict-origin-when-cross-origin",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <h3>{`Create your own thumbnail`}</h3>
    <p>{`Design a customized thumbnail that effectively represents your content and captures viewers’ attention. Consider using compelling imagery, clear text, and vibrant colors to make your thumbnail stand out and entice clicks.`}</p>
    <h3>{`Checking and publish`}</h3>
    <p>{`Check your content for accuracy, clarity, and consistency. Look for spelling and grammar mistakes, make sure all links work, and confirm that the thumbnail matches your content. When everything’s good to go, publish your content across platforms for your audience.`}</p>
    <h2>{`Recommended Figma Plugins`}</h2>
    <p>{`Some recommended plugins to simplify your workflow, task, accessibility checking, and design quality:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/1377553084101217769/legion-tokens-manager"
        }}><strong parentName="a">{`Legion Tokens Manager`}</strong></a>{`: A tool to manage design tokens like colors and fonts, ensuring consistency across projects.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/929607085343688745/color-shades"
        }}><strong parentName="a">{`Color Shades`}</strong></a>{`: A tool to createa and adjust color shades for a consistent and harmonious design.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/1298692189910272274/local-print"
        }}><strong parentName="a">{`Local Print`}</strong></a>{`: A feature to quickly print design assets for reviews or presentations.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/931280467863251825/adee-comprehensive-accessibility-tool"
        }}><strong parentName="a">{`Adee Comprehensive Accessibility Tool`}</strong></a>{`: Test color contrast, simulate 8 color blind simulations, and test touch target sizes to ensure they meet the various device standards.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/732603254453395948/stark-contrast-accessibility-tools"
        }}><strong parentName="a">{`Stark - Contrast & Accessibility Tools`}</strong></a>{`: Contrast Checker, Focus Order, Alt-Text Annotations, Vision Simulator, and more, all in one place.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.figma.com/community/plugin/798826066406007173/ditto-manage-copy-from-design-to-production"
        }}><strong parentName="a">{`Ditto`}</strong></a>{`: Single source of truth for everyone touching copy, from writers to designers to engineers.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      